import React, {useEffect, useState} from 'react';
import s from "../../TableBrand.module.css";
import {
    CURRENT_YEAR_MONTH,
    PREVIOUS_YEAR_MONTH, YEAR_MONTH_2019, YEAR_MONTH_2020,
    YEAR_MONTH_2021,
    YEAR_MONTH_2022,
    YEAR_MONTH_2023, YEAR_MONTH_2024
} from "../../../../../utils/consts";


const TableBodyRow = (props) => {
    const [Sum, SetSum] = useState(0)

    useEffect(() => {
        // let allBlock = document.querySelectorAll(`div[data-brand-id][data-mount-id]`)
        // await allBlock.forEach((el)=>{
        //     if (el.attributes['data-mount-id'].value !=='countYear'){
        //         el.innerHTML='0'
        //         el.style.color='grey'
        //     }
        // })
        let count = 0
        if (props.data[0]) {
            let year = new Date(props.stateYear * 1000).getFullYear()
            let yearEnd = Math.round(new Date(`1,1,${+year + 1}`) / 1000)


            props.data.filter(({date}) => date >= props.stateYear && date < yearEnd).forEach(el => {
                    let currentBlock = document.querySelector(`div[data-brand-id="${el.brand_id}"][data-mount-id="${el.date}"]`)
                    if (currentBlock) {
                        currentBlock.innerHTML = el.value
                        currentBlock.style.fontWeight = '500'
                        currentBlock.style.color = '#000'
                    }
                    count += +el.value
                }
            )
        }
        SetSum(count)
        if (!props.load) {
            props.setLoad(true)
        }

    }, [props.data, props.stateYear])

    if (props.stateYear == CURRENT_YEAR_MONTH.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    if (props.stateYear === YEAR_MONTH_2024.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    if (props.stateYear === YEAR_MONTH_2023.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    if (props.stateYear === YEAR_MONTH_2022.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    if (props.stateYear === YEAR_MONTH_2021.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    if (props.stateYear === YEAR_MONTH_2020.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    if (props.stateYear === YEAR_MONTH_2019.january) {
        return (
            <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
                <div data-brand-id={props.brand_id}
                     className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.january}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.february}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.march}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.april}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.may}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.june}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.july}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.august}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.september}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.october}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.november}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.december}
                     className={s.body_item}>0
                </div>
                <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                     className={s.body_item + ' countYear'}>{Sum}</div>
            </div>
        )
    }
    // if ( props.stateYear == 1577826000){
    //     return (
    //         <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
    //             <div data-brand-id={props.brand_id}
    //                  className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1577826000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1580504400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1583010000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1585688400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1588280400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1590958800"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1593550800"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1596229200"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1598907600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1601499600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1604178000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1606770000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
    //                  className={s.body_item + ' countYear'}>{Sum}</div>
    //         </div>
    //     )
    // }
    // if ( props.stateYear == 1546290000){
    //     return (
    //         <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
    //             <div data-brand-id={props.brand_id}
    //                  className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1546290000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1548968400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1551387600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1554066000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1556658000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1559336400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1561928400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1564606800"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1567285200"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1569877200"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1572555600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1575147600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
    //                  className={s.body_item + ' countYear'}>{Sum}</div>
    //         </div>
    //     )
    // }
    // if ( props.stateYear == 1672520400){
    //     return (
    //         <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
    //             <div data-brand-id={props.brand_id}
    //                  className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1672520400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1675198800"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1677618000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1680296400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1682888400"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1685566800"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1688158800"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1690837200"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1693515600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1696107600"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1698786000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={"1701378000"}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
    //                  className={s.body_item + ' countYear'}>{Sum}</div>
    //         </div>
    //     )
    // }
    // if ( props.stateYear == PREVIOUS_YEAR_MONTH.january){
    //     return (
    //         <div className={s.table_body_wrapper + ' wrap'} style={{order: Sum ? '-' + Sum : false,}}>
    //             <div data-brand-id={props.brand_id}
    //                  className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.january}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.february}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.march}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.april}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.may}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.june}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.july}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.august}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.september}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.october}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.november}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={PREVIOUS_YEAR_MONTH.december}
    //                  className={s.body_item}>0
    //             </div>
    //             <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
    //                  className={s.body_item + ' countYear'}>{Sum}</div>
    //         </div>
    //     )
    // }

};

export default TableBodyRow;