import React, {memo, useEffect, useRef, useState} from 'react';
import {Bar} from "react-chartjs-2";
import cls from './ChartBar5Year.module.scss'
import {getCarsInTheLast5Years, getCarsInTheLast5YearsLCV, getCarsInTheLast5YearsPC} from "../../../http/dataForPdf";
import Spinner from "../../Ui/Spinner/Spinner";
import pc from "../../../pc.png";
import lcv from "../../../lcv.png";


const optionsChart = {
    animation: false,
    scales: {
        // x: {
        //     grid: {
        //         display: true, // устанавливаем значение false для отключения сетки по оси X
        //     },
        // },
        // y: {
        //     grid: {
        //         display: true, // устанавливаем значение false для отключения сетки по оси Y
        //     },
        // },
    },
    plugins: {
        tooltip: {
            displayColors: false,
            titleColor: '#fff',
            enabled: true,
        },
        datalabels: {
            display: true, // включаем отображение меток данных
            weight: 'bold',
            color: '#000',
            anchor: 'end', // Устанавливаем якорь для меток данных
            align: 'top', // Устанавливаем выравнивание меток данных
            offset: 0, // Устанавливаем смещение меток данных вверх

        },
        legend: {
            display: true,
            labels: {
                font: {
                    size: 0
                },

            },
            title: {
                display: false,
                text: 'Продажи за последние 5 лет',
                color: 'goldenrod',
                font: {
                    size: 24
                }
            },
        }
    }
};

const ChartBar5Year = ({getRefToPdf, getLastMonthDate, car_type, ...props}) => {
    const [dataForChart, setDataForChart] = useState(null)
    const [dataAllCars, setDataAllCars] = useState(null)
    const [dataPC, setDataPC] = useState(null)
    const [dataLCV, setDataLCV] = useState(null)
    const ref = useRef()
    let dataChart = {
        labels: [],
        datasets: [{
            label: 'Данные',
            data: [],
            backgroundColor: ['#1d81a2'],
            fill: false,
            axis: 'x'
        }]
    };
    useEffect(() => {
        setDataPC(null)
        setDataLCV(null)
        setDataAllCars(null)
        setDataForChart(null)
        getCarsInTheLast5Years().then((data) => {
            let newArr = []
            data.data.data.forEach((el) => {
                if (el.year != "2019") {
                    newArr.push(el)
                }

            })
            setDataAllCars(newArr)
            if (car_type === 'all') {
                data.data.data.forEach(({year, value}) => {
                    dataChart.labels.push(year)
                    dataChart.datasets[0].data.push(value)
                })
                setDataForChart(dataChart)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBar5YearALL')
                }, 2000)
            }

        })
        getCarsInTheLast5YearsLCV().then((data) => {
            let newArr = []
            data.data.data.forEach((el) => {
                if (el.year != "2019") {
                    newArr.push(el)
                }

            })
            setDataLCV(newArr)
            if (car_type === 'lcv') {
                data.data.data.forEach(({year, value}) => {
                    dataChart.labels.push(year)
                    dataChart.datasets[0].data.push(value)
                })
                setDataForChart(dataChart)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBar5YearLCV')
                }, 2000)
            }
        })
        getCarsInTheLast5YearsPC().then((data) => {
            let newArr = []
            data.data.data.forEach((el) => {
                if (el.year != "2019") {
                    newArr.push(el)
                }

            })
            setDataPC(newArr)
            if (car_type === 'pc') {
                data.data.data.forEach(({year, value}) => {
                    dataChart.labels.push(year)
                    dataChart.datasets[0].data.push(value)
                })
                // const backgroundColor = dataChart.labels.map((label) => label === '2024' ? 'rgba(14,81,103,0.45)' : '#1d81a2');
                const backgroundColor = dataChart.labels.map((label) => label === '2001' ? 'rgba(14,81,103,0.45)' : '#1d81a2');
                dataChart.datasets[0].backgroundColor = backgroundColor;
                setDataForChart(dataChart)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBar5YearPC')
                }, 2000)

            }
        })
        return () => {

            setDataPC(null)
            setDataLCV(null)
            setDataAllCars(null)
            setDataForChart(null)
        }

    }, [car_type])

    const calculateTheDifference = (arr) => {
        if (arr) {
            const prevValue = parseInt(arr[arr.length - 2].value);
            const lastValue = parseInt(arr[arr.length - 1].value);
            const diffPercent = ((lastValue - prevValue) / prevValue) * 100;
            return diffPercent.toFixed(2)
        }
        return 0
    }

    return (
        <div ref={ref}>


            {
                car_type === 'pc'
                    ? <div className={cls.title}>
                        <h2> Рынок легковых автомобилей БАА <br/> <span>Продажи за последние 5 лет</span></h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={pc} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'lcv'
                    ? <div className={cls.title}>
                        <h2> Рынок легких коммерческих автомобилей БАА <br/> <span>Продажи за последние 5 лет</span></h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'all'
                    ? <div className={cls.title}>
                        <h2> Рынок транспортных средств БАА <br/> <span>Продажи за последние 5 лет</span></h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                <img src={pc} alt="logo"/>
                                <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }

            {dataForChart
                ? <>
                    <div className={cls.chartBlock}>
                        <Bar {...props} options={optionsChart} data={dataForChart}/>
                    </div>
                    <div className={[cls.charRow, cls.gray].join(' ')}>
                        <p></p>
                        {dataPC && dataPC.map(({value, year}, index) =>
                            <p key={index}>{year}</p>
                        )}
                        <p>23 / 22</p>
                    </div>

                    <div className={car_type && car_type === 'pc'
                        ? [cls.charRow, cls.active].join(' ')
                        : cls.charRow}
                    >
                        <p>PC (легковые)</p>
                        {dataPC && dataPC.map(({value, year}, index) =>
                            <p key={index}>{value}</p>
                        )}
                        <p>{calculateTheDifference(dataPC)}%</p>
                    </div>
                    <div
                        className={car_type && car_type === 'lcv'
                            ? [cls.charRow, cls.active].join(' ')
                            : cls.charRow}
                    >
                        <p>LCV (лёгкие коммерческие)</p>
                        {dataLCV && dataLCV.map(({value, year}, index) =>
                            <p key={index}>{value}</p>
                        )}
                        <p>{calculateTheDifference(dataLCV)}%</p>
                    </div>
                    <div className={car_type && car_type === 'all'
                        ? [cls.charRow, cls.active].join(' ')
                        : cls.charRow}>
                        <p>Все транспортные средства:</p>
                        {dataAllCars && dataAllCars.map(({value, year}, index) =>
                            <p key={index}>{value}</p>
                        )}
                        <p>{calculateTheDifference(dataAllCars)}%</p>
                    </div>
                </>
                : <div className={cls.spinner_block}>
                    <Spinner/>
                </div>
            }


        </div>


    );
};

export default memo(ChartBar5Year);