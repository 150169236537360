import React, {memo, useEffect, useRef, useState} from 'react';
import {Bar, Line} from "react-chartjs-2";
import {
    getTop30BrandsCurrentAndPrevMonth, getTop30BrandsCurrentAndPrevMonthLCV, getTop30BrandsCurrentAndPrevMonthPC,
    getTop30BrandsCurrentAndPrevYear,
    getTop30BrandsCurrentAndPrevYearLCV,
    getTop30BrandsCurrentAndPrevYearPC
} from "../../../http/dataForPdf";
import cls from './ChartBarTop30BrandByMonth.module.scss'
import Spinner from "../../Ui/Spinner/Spinner";
import pc from "../../../pc.png";
import lcv from "../../../lcv.png";

function transformData(obj) {
    const result = [];
    obj.data.forEach(item => {
        const brand = obj.included.brands.find(brandItem => brandItem.id === item.brand_id);
        result.push({
            value: item.value,
            brand_id: item.brand_id,
            year: item.year,
            brand_name: brand ? brand.name : ''
        });
    });

    return result
}

function calculateData(arr) {
    const result = {};

    arr.forEach(item => {
        const brandName = item.brand_name;
        const value = parseInt(item.value);
        const year = parseInt(item.year);

        if (year === 2024) {
            if (!result[brandName]) {
                result[brandName] = {
                    value2023: value,
                    value2024: 0,
                    diffPercentage: 0
                };
            } else {
                result[brandName].value2023 += value;
            }
        } else if (year === 2025) {
            if (result[brandName]) {
                result[brandName].value2024 = value;
                result[brandName].diffPercentage = (((value - result[brandName].value2023) / result[brandName].value2023) * 100).toFixed(2);
            }
        }
    });

    return result;
}

const mass = [

    {value: '4355', brand_id: '11', year: '2022', brand_name: 'Geely'},
    {value: '3782', brand_id: '18', year: '2023', brand_name: 'Geely'},
    {value: '1095', brand_id: '36', year: '2022', brand_name: 'Volkswagen'},
    {value: '997', brand_id: '30', year: '2023', brand_name: 'Renault'},
    {value: '0', brand_id: '13', year: '2022', brand_name: 'Hyundai'},
    {value: '0', brand_id: '13', year: '2023', brand_name: 'Hyundai'},
]

function aggregateSalesByBrandAndYear(data) {
    const salesByBrandAndYear = {};
    const years = [];
    for (const item of data) {
        const brandKey = `${item.brand_name}_${item.brand_id}`;
        const year = item.year;
        years.push(year);
        if (!salesByBrandAndYear[brandKey]) {
            salesByBrandAndYear[brandKey] = {};
        }
        if (!salesByBrandAndYear[brandKey][year]) {
            salesByBrandAndYear[brandKey][year] = 0;
        }
        salesByBrandAndYear[brandKey][year] += parseInt(item.value);
    }
    const uniqueYears = [...new Set(years)];
    uniqueYears.sort();
    const resultArray = [];
    for (const brandKey in salesByBrandAndYear) {
        const [brand_name, brand_id] = brandKey.split('_');
        const yearsData = salesByBrandAndYear[brandKey];
        const resultItem = {
            brand_name: brand_name,
            brand_id: brand_id,
        };
        for (const year of uniqueYears) {
            resultItem[year] = yearsData[year] || 0;
        }
        // Вычисление разницы между 2023 и 2022 годом
        resultItem['difference'] = resultItem['2025'] - resultItem['2024'];
        // Вычисление роста между 2022 и 2023 годом в процентах
        const growth = (resultItem['2023'] !== 0) ? ((resultItem['2025'] - resultItem['2024']) / Math.abs(resultItem['2024'])) * 100 : (resultItem['2025'] !== 0 ? 100 : 0);
        resultItem['growth_rate'] = isFinite(growth) ? growth.toFixed(2) : 0;
        if (resultItem['growth_rate'] === "Infinity") {
            resultItem['growth_rate'] = "100";
        }
        resultArray.push(resultItem);
    }
    // Сортировка по ключу 2023 в порядке убывания
    resultArray.sort((a, b) => b['2025'] - a['2025']);
    return resultArray;
}


const ChartBarTop30BrandByMonth = ({car_type, getRefToPdf, numericPrevMonth, getLastMonthDate}) => {
    const [dataForChart, setDataForChart] = useState(null)
    const [dataForTable, setDataForTable] = useState(null)

    const ref = useRef(null)


    let chartData2022 = {
        label: '2024',
        data: [],
        backgroundColor: 'gray',
        borderColor: 'gray',
        yAxisID: "y-axis-density"
    };
    let chartData2023 = {
        label: '2025',
        data: [],
        backgroundColor: 'red',
        borderColor: 'rgba(0, 99, 132, 1)',
        yAxisID: "y-axis-density"
    };


    let dataChart = {
        labels: [],
        datasets: [chartData2023, chartData2022]
    };

    const setLabelsAndDataToChart = (data) => {
        data.slice(0, 10).forEach((el) => {
            dataChart.labels.push(el.brand_name)
            chartData2022.data.push(el['2024'])
            chartData2023.data.push(el['2025'])
        })
    }

    useEffect(() => {
        setDataForChart(null)
        if (car_type === 'pc') {

            getTop30BrandsCurrentAndPrevMonthPC(numericPrevMonth).then((data) => {
                let dataInBrandName = transformData(data.data)
                let actualData = aggregateSalesByBrandAndYear(dataInBrandName)
                setDataForTable(actualData)
                setLabelsAndDataToChart(actualData)
                setDataForChart(dataChart)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBarTop30BrandByMonthPC')
                }, 2000)
            })
        }
        if (car_type === 'lcv') {
            getTop30BrandsCurrentAndPrevMonthLCV(numericPrevMonth).then((data) => {
                let dataInBrandName = transformData(data.data)
                let actualData = aggregateSalesByBrandAndYear(dataInBrandName)
                setDataForTable(actualData)
                setLabelsAndDataToChart(actualData)
                setDataForChart(dataChart)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBarTop30BrandByMonthLCV')
                }, 2000)
            })
        }
        if (car_type === 'all') {
            getTop30BrandsCurrentAndPrevMonth(numericPrevMonth).then((data) => {
                let dataInBrandName = transformData(data.data)
                let actualData = aggregateSalesByBrandAndYear(dataInBrandName)
                setDataForTable(actualData)
                setLabelsAndDataToChart(actualData)
                setDataForChart(dataChart)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBarTop30BrandByMonthALL')
                }, 2000)

            })
        }
        return () => {
            setDataForChart(null)
        }

    }, [car_type])


    return (
        <div ref={ref}>
            {
                car_type === 'pc'
                    ? <div className={cls.title}>
                        <h2> Рынок легковых автомобилей БАА <br/>
                            <span>Продажи по маркам, только {getLastMonthDate().toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                            })}</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={pc} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'lcv'
                    ? <div className={cls.title}>
                        <h2> Рынок легких коммерческих автомобилей БАА <br/>
                            <span>Продажи по маркам, только {getLastMonthDate().toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                            })}</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'all'
                    ? <div className={cls.title}>
                        <h2> Рынок транспортных средств БАА <br/>
                            <span>Продажи по маркам, только {getLastMonthDate().toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                            })}</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                <img src={pc} alt="logo"/>
                                <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {dataForChart
                ? <div className={cls.content}>
                    <div className={cls.chart}>
                        <Bar style={{width: '100%', height: '100%'}} data={dataForChart} options={{
                            animation: false,
                            scales: {
                                // x: {
                                //     grid: {
                                //         display: true, // устанавливаем значение false для отключения сетки по оси X
                                //     },
                                // },
                                // y: {
                                //     grid: {
                                //         display: true, // устанавливаем значение false для отключения сетки по оси Y
                                //     },
                                // },
                            },
                            plugins: {
                                tooltip: {
                                    displayColors: false,
                                    titleColor: '#fff',
                                    enabled: true,
                                },
                                datalabels: {
                                    display: true, // включаем отображение меток данных
                                    weight: 'bold',
                                    color: '#000',
                                    anchor: 'end', // Устанавливаем якорь для меток данных
                                    align: 'top', // Устанавливаем выравнивание меток данных
                                    offset: 0, // Устанавливаем смещение меток данных вверх

                                },
                                legend: {
                                    display: true,
                                    labels: {
                                        font: {
                                            size: 0
                                        },

                                    },
                                    title: {
                                        display: false,
                                        text: `Продажи по маркам, только ${numericPrevMonth} 2024 `,
                                        color: 'goldenrod',
                                        font: {
                                            size: 24
                                        }
                                    },
                                }
                            }
                        }}></Bar>
                    </div>
                    <div className={cls.table}>
                        <div className={cls.header}>
                            <div>№</div>
                            <div></div>
                            {/*<div>$MS</div>*/}
                            <div>Бренд</div>
                            <div>2024</div>
                            <div>2025</div>
                            <div>YoY</div>
                        </div>
                        {
                            dataForTable
                                ? dataForTable.slice(0, 30).map((el, index) =>
                                    <div key={index} className={cls.row}>
                                        <div>{index + 1}</div>
                                        <div>
                                        <span
                                            className={el.difference > 0 ? cls.green : cls.red}
                                        >
                                            {el.difference >= 0
                                                ? el.difference === 0 ? '' : '+' + el.difference
                                                : el.difference
                                            }
                                        </span>
                                        </div>
                                        {/*<div>{el.growth_rate}%</div>*/}
                                        <div>{el.brand_name}</div>
                                        <div>{el[2024]}</div>
                                        <div>{el[2025]}</div>
                                        <div className={cls.rate}>
                                            <span className={cls.red}>
                                                {
                                                    +el.growth_rate > 0 && <p>{el.growth_rate}%</p>
                                                }
                                                {
                                                    +el.growth_rate < 0 &&
                                                    <i style={{width: String(el.growth_rate).replace('-', '') + '%'}}></i>
                                                }

                                            </span>
                                            <span className={cls.green}>
                                                {
                                                    +el.growth_rate < 0 && <p>{el.growth_rate}%</p>
                                                }
                                                {
                                                    +el.growth_rate > 0 &&
                                                    <i style={{width: String(el.growth_rate).replace('-', '') + '%'}}></i>
                                                }
                                            </span>
                                            {/*<span*/}
                                            {/*    className={el.growth_rate < 0 ? cls.red : cls.green}*/}
                                            {/*>*/}
                                            {/*    <p style={{width:'100%',height:'18px', display:'flex',justifyContent:'center',alignItems:'center'}}><span>{el.growth_rate}%</span></p>*/}

                                            {/*    <i style={{width: el.growth_rate.replace('-', '') + '%'}}></i>*/}
                                            {/*</span>*/}
                                        </div>
                                    </div>
                                )
                                : ''
                        }

                        <div className={cls.footer}>
                            <div></div>
                            <div></div>
                            {/*<div></div>*/}
                            <div></div>
                            <div>2024</div>
                            <div>2025</div>
                            <div>YoY</div>
                        </div>
                    </div>
                </div>
                : <div className={cls.spinner_block}><Spinner/></div>}
        </div>

    );
};

export default memo(ChartBarTop30BrandByMonth);

