import React, {useEffect, useState} from 'react';
import s from "../../TablePcMarketShare.module.css";
import {
    CURRENT_YEAR_MONTH,
    PREVIOUS_YEAR_MONTH,
    YEAR_MONTH_2019,
    YEAR_MONTH_2020, YEAR_MONTH_2021,
    YEAR_MONTH_2022, YEAR_MONTH_2023, YEAR_MONTH_2024
} from "../../../../../utils/consts";


const TableBodyRowLCVMarket = (props) => {
    const [Sum, SetSum] = useState(0)

    useEffect(() => {


        let count = 0
        if (props.data[0]) {
            let year = new Date(props.stateYear * 1000 ).getFullYear()
            let yearEnd = Math.round(new Date(`1,1,${+year+1}`)/1000)
            props.data.filter(({date}) => date >= props.stateYear && date <yearEnd).forEach(el => {
                count += +el.value
            })
            props.data.forEach(el => {
                    let currentBlock = document.querySelector(`div[data-brand-id="${el.brand_id}"][data-mount-id="${el.date}"][data-market="${'lcv'}"]`)
                    if (currentBlock) {

                        let interest = +el.value / +props.countMonth[el.date] * 100 ? +el.value / +props.countMonth[el.date] * 100 + '' : '0'
                        currentBlock.innerHTML = interest.slice(0, 4) + '%'
                        currentBlock.style.fontWeight = '500'
                        currentBlock.style.color = '#000'
                    }
                }
            )
        }
        SetSum(count)

        if (!props.load) {
            props.setLoad(true)
        }

    }, [props.data])
    return (
        <>
            {
                props.stateYear == CURRENT_YEAR_MONTH.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={CURRENT_YEAR_MONTH.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={CURRENT_YEAR_MONTH.december}
                             className={s.body_item}>0
                        </div>
                        <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                             className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>

                    </div>
                    : ''
            }
                              {
                props.stateYear == YEAR_MONTH_2024.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand'+' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2024.december}
                             className={s.body_item}>0
                        </div>
                        <div  data-brand-id={props.brand_id} data-mount-id={'countYear'}
                              className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>
                    </div>
                    : ''
            }
            {
                props.stateYear == YEAR_MONTH_2023.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand' + ' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             data-mount-id={YEAR_MONTH_2023.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2023.december}
                             className={s.body_item}>0
                        </div>
                        <div data-brand-id={props.brand_id} data-mount-id={'countYear'}
                             className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>
                    </div>
                    : ''
            }
            {
                props.stateYear == YEAR_MONTH_2022.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand'+' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2022.december}
                             className={s.body_item}>0
                        </div>
                        <div  data-brand-id={props.brand_id} data-mount-id={'countYear'}
                              className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>
                    </div>
                    : ''
            }
            {
                props.stateYear == YEAR_MONTH_2021.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand'+' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021 .january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2021.december}
                             className={s.body_item}>0
                        </div>
                        <div  data-brand-id={props.brand_id} data-mount-id={'countYear'}
                              className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>
                    </div>
                    : ''
            }
            {
                props.stateYear == YEAR_MONTH_2020.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand'+' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020 .january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2020.december}
                             className={s.body_item}>0
                        </div>
                        <div  data-brand-id={props.brand_id} data-mount-id={'countYear'}
                              className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>
                    </div>
                    : ''
            }
            {
                props.stateYear == YEAR_MONTH_2019.january
                    ?
                    <div className={s.table_body_wrapper + ' wrap'}
                         style={{order: String(Sum).replace('%', '') ? '-' + String(Sum).replace('%', '') : false}}>
                        <div data-market={'lcv'} data-brand-id={props.brand_id}
                             className={s.body_item + ' header-brand'+' brand_name_block'}>{props.brand_name}</div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.january}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.february}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.march}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.april}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.may}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.june}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.july}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.august}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.september}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.october}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.november}
                             className={s.body_item}>0
                        </div>
                        <div data-market={'lcv'} data-brand-id={props.brand_id} data-mount-id={YEAR_MONTH_2019.december}
                             className={s.body_item}>0
                        </div>
                        <div  data-brand-id={props.brand_id} data-mount-id={'countYear'}
                              className={s.body_item + ' countYear'}

                        >
                            {(Sum / props.countMonth['allTime'] * 100 + '').slice(0, 4) + '%'}
                        </div>
                    </div>
                    : ''
            }

        </>

    );
};

export default TableBodyRowLCVMarket;